<template>
  <section class="page-interaction">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-tabs content-class="mt-3" v-model="activeTabIndex">
        <b-tab :title="$t('My gains')">
          <b-row v-if="data">
            <b-col xl="5" lg="6" md="8" sm="12">
              <div class="d-flex align-items-center">
                <b-img :src="iconBean" width="22" class="mr-05"/>
                <span class="total-gains-text">{{ data.earnedCredits }}</span>
              </div>
              <hr>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                         {{ $t('Live Streaming') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsStreamGift) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                         {{ $t('Voice Call') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsCall) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                         {{ $t('Video Call') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsVideoCall) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                         {{ $t('Random Video Call') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsRandomVideoCall) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                        {{ $t('Chat') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsChat) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                        {{ $t('Gifts') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsGift) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12">
                  <div class="d-flex justify-content-between w-100 gains-text">
                    <div class="d-flex align-items-center">
                      <b-img :src="iconBean" width="14" class="mr-05"/>
                      <span>
                        {{ $t('Bonus') }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      {{ numberFormatter(data.earnedCreditsBonusCredit) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="receivedCallInfo">
            <b-col xl="5" lg="6" md="8" sm="12">
              <hr>
              <div v-if="streamStatistics" class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Weekly streaming count') }}
                </span>
                <span>
                  {{ numberFormatter(streamStatistics.weeklyTotalCount) }}
                </span>
              </div>
              <div v-if="streamStatistics" class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Weekly streaming duration') }}
                </span>
                <span>
                  {{ timeLeft(streamStatistics.weeklyTotalCount) }}
                </span>
              </div>
              <div v-if="streamStatistics" class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Monthly streaming count') }}
                </span>
                <span>
                  {{ numberFormatter(streamStatistics.monthlyTotalCount) }}
                </span>
              </div>
              <div v-if="streamStatistics" class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Monthly streaming duration') }}
                </span>
                <span>
                  {{ timeLeft(streamStatistics.monthlyTotalSec) }}
                </span>
              </div>
              <hr>
              <div class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Number of calls this week') }}
                </span>
                <span>
                  {{ numberFormatter(receivedCallInfo.weeklyTotalCount) }}
                </span>
              </div>
              <div class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Calls duration this week') }}
                </span>
                <span>
                  {{ timeLeft(receivedCallInfo.weeklyTotalSec) }}
                </span>
              </div>
              <div class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Number of calls this month') }}
                </span>
                <span>
                  {{ numberFormatter(receivedCallInfo.monthlyTotalCount) }}
                </span>
              </div>
              <div class="d-flex color-black mb-1 justify-content-between">
                <span>
                  {{ $t('Calls duration this month') }}
                </span>
                <span>
                  {{ timeLeft(receivedCallInfo.monthlyTotalSec) }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="$t('Cashout Request')">
          <b-row v-if="data">
            <b-col xl="5" lg="6" md="8" sm="12">
              <div class="d-flex justify-content-center align-items-center flex-column">
                <span class="fs-14 color-gray">
                  {{ $t('Total Beans') }}
                </span>
                <span class="color-black fw-600 fs-32">
                  {{ data.earnedCredits }}
                </span>
              </div>
              <hr>
              <validation-observer
                  ref="gainRequestForm"
                  #default="{invalid}"
              >
                <b-form
                    class="mt-2"
                    @submit.prevent
                >
                  <b-form-group
                      label-for="gainRequestAccountHolder"
                      :label="$t('Account holder')"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Account holder')"
                        vid="gainRequestAccountHolder"
                        rules="required"
                    >
                      <b-form-input
                          id="gainRequestAccountHolder"
                          :disabled="loading || data.earnedCredits < 10000"
                          v-model="gainRequest.accountHolder"
                          :placeholder="$t('Account holder')"
                          rows="3"
                          max-rows="6"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                      label-for="gainRequestBankName"
                      :label="$t('Bank name')"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Bank name')"
                        vid="gainRequestBankName"
                        rules="required"
                    >
                      <b-form-input
                          id="gainRequestBankName"
                          :disabled="loading || data.earnedCredits < 10000"
                          v-model="gainRequest.bankName"
                          :placeholder="$t('Bank name')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                      label-for="gainRequestIban"
                      :label="$t('Account number')"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Account number')"
                        vid="iban"
                        rules="required"
                    >
                      <b-form-input
                          id="gainRequestIban"
                          :disabled="loading || data.earnedCredits < 10000"
                          v-model="gainRequest.iban"
                          :placeholder="$t('Account number')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                      variant="primary"
                      class="mt-2 px-4"
                      :disabled="loading || data.earnedCredits < 10000"
                      block
                      @click="makeGainRequest"
                  >
                    <b-spinner v-if="loading" small></b-spinner>
                    {{ $t('Create Cashout Request') }}
                  </b-button>
                  <div class="fs-14 color-gray text-center mt-1">
                    {{ $t('min_withdrawal_limit') }}
                  </div>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
          <b-row v-if="cashoutItems.length">
            <b-col xl="5" lg="6" md="8" sm="12">
              <b-table :items="cashoutItems" :busy="cashoutLoading" :fields="cashoutFields" class="mt-3" borderless>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t('Loading') }}...</strong>
                  </div>
                </template>
                <template #cell(credits)="data">
                  <span class="fs-16 fw-300 color-black">
                    {{ data.item.credits }}
                  </span>
                </template>
                <template #cell(createdAt)="data">
                  <span class="fs-16 fw-300 color-black">
                    {{ data.item.createdAt }}
                  </span>
                </template>
                <template #cell(status)="data">
                  <span v-if="data.item.status === 'requested'"
                        class="fs-16 fw-600 color-black">
                    {{ $t('Request sent') }}
                  </span>
                  <span v-if="data.item.status === 'rejected'"
                        class="fs-16 fw-600" style="color: #ef584d">
                    {{ $t('Rejected') }}
                  </span>
                  <span v-if="data.item.status === 'done'"
                        class="fs-16 fw-600" style="color: #399a24">
                    {{ $t('Done') }}
                  </span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </section>
</template>

<script>
// freeCallUser
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAlert, BAvatar, BBadge, BButton, BCard, BCarousel, BCarouselSlide, BCol, BDropdown, BDropdownItem, BForm, BFormGroup,
  BFormInput, BFormTextarea, BImg, BLink, BMedia, BOverlay, BPagination, BRow, BSpinner, BTable, BTabs, BTab,
} from 'bootstrap-vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import useJwt from '@/auth/jwt/useJwt'
import {showNotify, throwDefaultError, isValidIBANNumber, numberFormatter} from '@/helpers/helpers'
import StatusIndicator from '@/components/StatusIndicator'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import CallCard from '@/components/CallCard'
import vSelect from 'vue-select'
import moment from 'moment'
import _ from "lodash";

// import { getUser } from '@/auth/utils'

export default {
  name: 'GainsIndex',
  components: {
    BAlert,
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCarousel,
    BCarouselSlide,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BImg,
    BLink,
    BMedia,
    BOverlay,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BTabs,
    BTab,

    CallCard,
    DiscoverNoUser,
    StatusIndicator,

    Carousel3d,
    Slide,

    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      activeTabIndex: 0,
      cashoutLoading: false,
      cashouts: [],
      data: null,
      gainRequest: {
        accountHolder: '',
        bankName: '',
        iban: '',
      },
      iconBean: require('@/assets/images/icons/bean.png'),
      iconDiamond: require('@/assets/images/icons/diamonds-1.svg'),
      iconPhone: require('@/assets/images/icons/phone-green.svg'),
      limit: 300,
      loading: false,
      receivedCallInfo: null,
      streamStatistics: null,
    }
  },
  computed: {
    cashoutFields() {
      return [
        { key: 'credits', label: this.$t('Drawn bean') },
        { key: 'createdAt', label: this.$t('') },
        { key: 'status', label: this.$t('Status') },
      ]
    },
    cashoutItems() {
      return this.cashouts.map(cashout => {
        return {
          credits: cashout.credits,
          status: cashout.status,
          createdAt: this.getDate(cashout.createdAt),
        }
      })
    }
  },
  created() {
    this.getGains()
    this.getCashouts()
    this.getReceivedCallInfo()
  },
  methods: {
    numberFormatter,
    getGains() {
      this.loading = true
      useJwt.accountCredits()
          .then(response => {
            console.log('response.data.body', response.data.body)
            this.data = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getCashouts() {
      this.cashoutLoading = true
      useJwt.getCashouts()
          .then(response => {
            this.cashouts = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.cashoutLoading = false })
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    getReceivedCallInfo() {
      useJwt.getMe()
          .then(response => {
            try {
              this.receivedCallInfo = response.data.body.receivedCallInfo
              this.streamStatistics = response.data.body.streamStatistics || {
                "weeklyTotalSec": 0,
                "weeklyTotalCount": 0,
                "monthlyTotalSec": 0,
                "monthlyTotalCount": 0
              }
            } catch (e) {
              // console.log(e)
            }
          })
    },
    makeGainRequest() {
      this.$refs.gainRequestForm.validate().then(success => {
        if (success) {
          if (!isValidIBANNumber(this.gainRequest.iban)) {
            this.$refs.gainRequestForm.setErrors({
              iban: this.$t('Please enter a valid IBAN number'),
            })
            return
          }
          // this.loading = true
          // useJwt.postCashout()
          //     .then(response => {
          //
          //     })
          //     .catch(error => {
          //       throwDefaultError(this.$toast, error)
          //     })
          //     .finally(() => { this.loading = false })
          this.loading = true
          useJwt.postPaymentsCashout({...this.gainRequest})
              .then(response => {
                this.gainRequest.accountHolder = ''
                this.gainRequest.bankName = ''
                this.gainRequest.iban = ''
                this.$refs.gainRequestForm.reset()
                this.getCashouts()
                this.getGains()
                showNotify(this.$toast, this.$t('Your withdrawal request has been created') + '.', 'success')
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => { this.loading = false })
        }
      })
    },
    timeLeft(time) {
      if (time <= 0) return '0'

      let result = ''
      let totalSeconds = time;

      const days = Math.floor(totalSeconds / (60 * 60 * 24))
      totalSeconds -= days * (60 * 60 * 24)

      const hours = Math.floor(totalSeconds / (60 * 60))
      totalSeconds -= hours * (60 * 60)

      const minutes = Math.floor(totalSeconds / (60))
      totalSeconds -= minutes * 60

      const seconds = Math.floor(totalSeconds % 60)

      // if (days > 0) {
      //   result += days + ` ${this.$t('days')}, `
      // }

      if (hours > 0) {
        result += hours + ` ${this.$t('hours')}, `
      }

      if (minutes > 0) {
        result += minutes + ` ${this.$t('minutes')}, `
      }

      if (seconds > 0) {
        result += seconds + ` ${this.$t('seconds')}, `
      }

      return _.trimEnd(result, ', ')
    }
  }
}
</script>

<style lang="scss" scoped>
.total-gains-text {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
.gains-text {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
</style>
